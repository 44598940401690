import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem, Select } from "@mui/material";
import './PersonalDataForm.css';
import { ProjectService } from '../../../utils/ApiDataHandler';

const PersonalDataForm = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [salutations, setSalutations] = useState([]);
    const [positions, setPositions] = useState([]);
    const [salutation, setSalutation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [position, setPosition] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [linkedInUrl, setLinkedInUrl] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const data = await ProjectService.fetchUserProfile();
                if (data.success) {
                    const profile = data.data;
                    setSalutation(profile.salutation_id);
                    setFirstName(profile.first_name);
                    setLastName(profile.last_name);
                    setPosition(profile.position_id || 3);
                    setLinkedInUrl(profile.linkedIn_url || '');
                } else {
                    console.error('Failed to fetch profile:', data.message);
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        const fetchSalutations = async () => {
            try {
                const data = await ProjectService.fetchSalutations();
                if (data.success) {
                    setSalutations(data.data);
                } else {
                    console.error('Failed to fetch salutations:', data.message);
                }
            } catch (error) {
                console.error('Error fetching salutations:', error);
            }
        };

        const fetchPositions = async () => {
            try {
                const data = await ProjectService.fetchPositions();
                setPositions(data);

            } catch (error) {
                console.error('Error fetching positions:', error);
            }
        };

        fetchUserProfile();
        fetchSalutations();
        fetchPositions();
    }, []);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const userData = {
                first_name: firstName,
                last_name: lastName,
                position_id: position,
                salutation_id: salutation,
                linkedIn_url: linkedInUrl,
                profile_image: profileImage
            };

            const result = await ProjectService.updateUserProfile(userData);
            if (result.success) {
                setIsEditing(false);
            } else {
                console.error('Failed to update profile:', result.message);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const salutationName = salutations.find(s => s.id === salutation)?.name || 'N/A';
    const positionName = positions.find(p => p.id === position)?.name || 'N/A';

    return (
        <div className="personal-data">
            <h3>Personal Data</h3>
            <div className="form-group">
                <label>Salutation: </label>
                {isEditing ? (
                    <Select
                        variant="outlined"
                        value={salutation}
                        onChange={(e) => setSalutation(e.target.value)}
                        size="small"
                        autoWidth
                        sx={{
                            borderRadius: '5px',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {salutations.map((sal) => (
                            <MenuItem key={sal.id} value={sal.id}>
                                {sal.name}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <span>{salutationName}</span>
                )}
            </div>

            <div className="form-group side-by-side">
                <div>
                    <label>First name: </label>
                    {isEditing ? (
                        <TextField
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            size="small"
                        />
                    ) : (
                        <span>{firstName}</span>
                    )}
                </div>
                <div>
                    <label>Last name: </label>
                    {isEditing ? (
                        <TextField
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            size="small"
                        />
                    ) : (
                        <span>{lastName}</span>
                    )}
                </div>
            </div>

            {/* <div className="form-group">
                <label>Position* </label>
                {isEditing ? (
                    <Select
                        variant="outlined"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        size="small"
                        autoWidth
                        sx={{
                            borderRadius: '5px',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {positions.map((pos) => (
                            <MenuItem key={pos.id} value={pos.id}>
                                {pos.name}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <span>{positionName}</span>
                )}
            </div>

            <div className="form-group bold">
                <label>
                    {isEditing ? (
                        'LinkedIn Profil'
                    ) : linkedInUrl ? (
                        <a
                            href={linkedInUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkedin-link"
                        >
                            LinkedIn Profil
                        </a>
                    ) : (
                        'LinkedIn Profil'
                    )}
                </label>

                {isEditing && (
                    <TextField
                        variant="outlined"
                        value={linkedInUrl}
                        onChange={(e) => setLinkedInUrl(e.target.value)}
                        size="small"
                    />
                )}
            </div>
*/}
            <div className="button-group">
                <Button
                    onClick={isEditing ? handleSave : handleEdit}
                    style={{
                        width: "100px",
                        backgroundColor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                    }}
                    className="custom-button"
                >
                    {isEditing ? 'Save' : 'Change'}
                </Button>
            </div>
        </div>
    );
};

export default PersonalDataForm;
