import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, TextField, Link, FormControl, InputLabel, OutlinedInput, Typography, InputAdornment, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { cardContext, loginContext } from '../../hooks/customHooks/Context'
import { AuthService } from '../../utils/ApiDataHandler';
import "./Login.css"
import { CustomCard } from '../../customComponents/CustomCard';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import { useNavigate } from 'react-router-dom';

function Login() {
    const { setIsAuthenticated, setShowOverviewPage, setShowLoginPage } = useContext(loginContext);
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSignup, setShowSignup] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();


    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await AuthService.login(email, password);
            if (response.success) {
                setIsAuthenticated(true);
                setShowOverviewPage(true);
                setShowLoginPage(false)
                localStorage.setItem('token', response.data.access_token)
                navigate('/');
            } else {
                setErrorMessage(response.message || 'Failed to sign up. Please try again.');
            }

        } catch (error) {
            setErrorMessage('An unexpected error occurred. Please try again.');
        }

    };


    useEffect(() => {
        if (!showSignup) {
            setHeading('Log in to TechScaler');
            setDescription('');
        }
        setCardWidth("442px");
        setTextAlign("center");
    }, [showSignup, setHeading, setDescription, setCardWidth, setTextAlign]);


    if (showSignup) {
        return <Signup onLoginClick={() => setShowSignup(false)} />;
    }
    if (showForgotPassword) {
        return <ForgotPassword onBackToLogin={() => setShowForgotPassword(false)} />;
    }

    return (
        <Box className="login-container">
            <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
                <Box mb={2} textAlign="left">
                    <Typography variant="subtitle1" sx={{ mb: -1 }} >Email*</Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '5px',

                        }}
                    />
                </Box>
                <Box mb={2} textAlign="left">
                    <Typography variant="subtitle1" sx={{ mb: -1 }}>Password*</Typography>
                    <FormControl variant="outlined" margin="normal" required fullWidth sx={{ backgroundColor: 'white', borderRadius: '5px' }}>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#16697a',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#16697a'
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
                <Box className="checkbox-button-container">
                    <Box className="checkbox-label">
                        <FormControlLabel
                            control={<Checkbox style={{ color: "#1c5a73" }} value="remember" color="primary" />}
                            label="Remember me"
                        />
                    </Box>
                    <Box mb={2}>
                        <Button type="submit" className="login-button" sx={{
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}>
                            Log In
                        </Button>
                    </Box>
                </Box>
                {errorMessage && (
                    <Typography className="error-message">
                        {errorMessage}
                    </Typography>
                )}
                <Box className="bottom-left-links">
                    <Link href="#" variant="body2" className="link" onClick={() => setShowForgotPassword(true)}>
                        Forgot password?
                    </Link>
                    <Link href="#" variant="body2" onClick={() => {
                        setShowSignup(true);
                    }} className="link">
                        Don't have an account? Sign up
                    </Link>
                </Box>

            </form>
        </Box>
    );

}

export default CustomCard(Login)