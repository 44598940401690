import { Button } from '@mui/material';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import CustomWorldMap from '../../customComponents/CustomWorldMap';
import { projectContext, cardContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';

const RegionsPage = (props) => {
    const [data, setData] = useState(null);
    const [selectedRegionData, setSelectedRegionData] = useState([]);
    const { selectedProjectData } = useContext(projectContext);
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const getCountryISO2 = require("country-iso-3-to-2");

    useEffect(() => {
        setHeading('Are there any regions you are particularly interested in? ');
        setDescription('If you already have certain regions in mind that you would like to compare, you can select them here. Our analysis then compares the regions with other regions worldwide. ');
        setCardWidth("100%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const updateRegionData = async () => {
        try {

            const countries = selectedRegionData.map(item => ({
                country_id: item.id,
                result: ""
            }));

            const projectData = {
                project_id: selectedProjectData.id,
                current_step: 4,
                countries: countries
            };

            await ProjectService.updateProjectCountries(projectData);
        } catch (error) {
            console.error("Error updating region data:", error);
        }
    };

    const onClickBack = async () => {
        await updateRegionData();
        props.handleBack();
    };

    const onClickNext = async () => {
        await updateRegionData();
        props.handleNext();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await ProjectService.fetchCountries();
                setData(result);
            } catch (error) {
                console.error("Error fetching countries data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedProjectData && selectedProjectData.countries && data) {
            const formattedCountries = selectedProjectData.countries.map(country => {
                // Find the corresponding country in the fetched data using country_id
                const matchingCountry = data.find(item => item.id === country.country_id);

                return {
                    id: country.country_id,
                    country: matchingCountry ? getCountryISO2(matchingCountry.countrycode) : "", // Use matching country ISO2 code
                    countryName: country.name
                };
            });
            // Set the transformed data in selectedRegionData
            setSelectedRegionData(formattedCountries);
        }
    }, [selectedProjectData, data]);

    return (
        <Fragment>
            <CustomWorldMap
                selectedRegionData={selectedRegionData}
                setSelectedRegionData={setSelectedRegionData}
                countries={data}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                <Button
                    onClick={onClickBack}
                    sx={{
                        width: "100px",
                        bgcolor: '#ffffff',
                        color: '#16697A',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={onClickNext}
                    sx={{
                        width: "100px",
                        bgcolor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Next
                </Button>
            </div>
        </Fragment>
    );
};

// Wrap RegionsPage with the CustomCard HOC
export default CustomCard(RegionsPage);
