import './App.css';
import React from 'react';
import LoginContexProvider from './hooks/customHooks/LoginContexProvider';
import { CardContextProvider } from './hooks/customHooks/CardContextProvider';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/header/Navbar';
import Footer from './components/footer/Footer';
import FAQ from './components/header/FAQ';
import Body from './components/body/Body';
import { Grid } from '@mui/system';
import TermsAndConditions from './utils/TermsAndConditions';
import PrivacyPolicy from './utils/PrivacyPolicy';
import LandingPage from './components/body/LandingPage';
import Login from './components/header/Login';
import Signup from './components/header/Signup';

function App() {
  return (
    <div className="App">
      <LoginContexProvider>
        <CardContextProvider>
          <Grid container spacing={0}>
            <Grid size={12}>
              <Navbar />
            </Grid>
            <Grid className="body" size={12}>
              <Routes>
                <Route path="/" element={<Body />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/faqs" element={<FAQ />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/landing" element={<LandingPage />} />
              </Routes>
            </Grid >
            <Grid >
              <Footer />
            </Grid>
          </Grid >
        </CardContextProvider>
      </LoginContexProvider>
    </div>
  );
}

export default App;

