import React, { useContext, useState, useEffect } from 'react';
import './Profile.css';
import PersonalDataForm from './ProfileForms/PersonalDataForm';
import CompanyDataForm from './ProfileForms/CompanyDataForm';
import PasswordForm from './ProfileForms/PasswordForm';
import DeleteAccountForm from './ProfileForms/DeleteAccountForm';
import { CustomCard } from '../../customComponents/CustomCard';
import { cardContext } from '../../hooks/customHooks/Context';
import { Divider } from '@mui/material';
import { ProjectService } from '../../utils/ApiDataHandler';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


const Profile = ({ onClose }) => {
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const [selectedSection, setSelectedSection] = useState('PersonalData');
    useEffect(() => {
        setHeading('Profile');
        setDescription('See your personal details here.');
        setCardWidth("100%");
        setTextAlign("left");
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const renderForm = () => {
        switch (selectedSection) {
            case 'PersonalData':
                return <PersonalDataForm />;
            case 'CompanyData':
                return <CompanyDataForm />;
            case 'Password':
                return <PasswordForm />;
            case 'DeleteAccount':
                return <DeleteAccountForm />;
            default:
                return null;
        }
    };
    const getButtonClass = (section) => {
        return section === selectedSection ? 'selected' : '';
    };
    return (<div className="profile-page">
        <div className="side-menu">
            <button className={getButtonClass('PersonalData')} onClick={() => setSelectedSection('PersonalData')}>Personal Data</button>
            <button className={getButtonClass('CompanyData')} onClick={() => setSelectedSection('CompanyData')}>Company Data</button>
            <button className={getButtonClass('Password')} onClick={() => setSelectedSection('Password')}>Password</button>
            <button className={getButtonClass('Plan')} onClick={() => setSelectedSection('Plan')}>Plan</button>
            <button className={getButtonClass('Billing')} onClick={() => setSelectedSection('Billing')}>Billing</button>
            <button className={getButtonClass('Notifications')} onClick={() => setSelectedSection('Notifications')}>Notifications</button>
            {/* <button className={getButtonClass('Team')} onClick={() => setSelectedSection('Team')}>Team</button>*/}
            <button className={getButtonClass('DeleteAccount')} onClick={() => setSelectedSection('DeleteAccount')}>Delete Account</button>


        </div>
        <Divider orientation="vertical" flexItem className="vertical-divider" />
        <div className="content">
            {renderForm()}
        </div>

        <Button
            variant="contained"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 16,
                bottom: 16,
                width: '100px',
                backgroundColor: '#16697A',
                color: '#ffffff !important',
                textTransform: 'none',
                borderRadius: '5px',
                '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
            }}
        >
            Close
        </Button>
    </div>
    );
};




export default CustomCard(Profile);