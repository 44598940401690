import React, { useState } from 'react'
import { cardContext } from './Context'
export const CardContextProvider = ({ children }) => {

    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState("");
    const [cardWidth, setCardWidth] = useState("60%")
    const [textAlign, setTextAlign] = useState("left");
    const [marginTop, setMarginTop] = useState("0%");
    return (
        <cardContext.Provider value={{
            heading,
            description,
            cardWidth,
            setHeading,
            setDescription,
            setCardWidth,
            textAlign,
            setTextAlign,
            marginTop,
            setMarginTop
        }} >
            {children}
        </cardContext.Provider>
    )
}
