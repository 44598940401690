import { Button } from '@mui/material';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import { projectContext, cardContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';
import IndicatorWeighting from './IndicatorWeighting';
import RequirementWeighting from './RequirementWeighting';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import './Weighting.css';

const Weighting = (props) => {
    const [isShowIndicatorWeighting, setIsShowIndicatorWeighting] = useState(false);
    const { selectedProjectData, setSelectedProjectData, updateProjectData } = useContext(projectContext);
    const [requirementData, setRequirementData] = useState(selectedProjectData.requirements || []);
    const [indicatorData, setIndicatorData] = useState(selectedProjectData.indicators || []);
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');



    useEffect(() => {
        setHeading('Which requirement is how important to you? ');
        setDescription('Indicate the relative importance of the market requirements by weighting each requirement on a scale from 1 (low weight) to 10 (high weight). Note that each requirement is weighted in relation to the other requirements. So, if you set all requirements to a weight of 10, this results in the same weighting as a weight of 1 for all of them. The pie chart visualizes your weighting for you. ');
        setCardWidth("100%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);
    const updateRequirementStates = async () => {

        if (requirementData.length > 0) {
            const updatedRequirementData = requirementData.map(requirement => ({
                category_id: requirement.category_id,
                requirement_id: requirement.requirement_id,
                priority: requirement.priority,
                weighting: requirement.weighting
            })).filter(item => item !== null);

            if (updatedRequirementData.length === 0) {
                console.warn('No valid requirements to update.');
                return;
            }

            try {
                // Send updated requirements to the server
                await ProjectService.updateProjectRequirements({
                    project_id: selectedProjectData.id,
                    //  category_id: requirement.category_id,
                    current_step: selectedProjectData.current_step,
                    requirements: updatedRequirementData
                });

                // Fetch and update project data after updating requirements
                const updatedProjects = await ProjectService.fetchProjectById(selectedProjectData.id);
                //updateProjectData(updatedProjects);
                const updatedProject = updatedProjects;
                if (updatedProject) {
                    setSelectedProjectData(updatedProject);
                }
            } catch (error) {
                console.error('Failed to update requirement states:', error);
            }
        }
    };

    const updateIndicatorsState = async () => {


        if (indicatorData.length > 0) {
            const updatedIndicatorsData = indicatorData.map(indicator => ({
                indicator_id: indicator.indicator_id,
                requirement_id: indicator.requirement_id,
                weighting: indicator.weighting

            })).filter(item => item !== null);

            if (updatedIndicatorsData.length === 0) {
                console.warn('No valid requirements to update.');
                return;
            }

            try {
                // Send updated requirements to the server
                await ProjectService.updateProjectIndicators({
                    project_id: selectedProjectData.id,
                    current_step: selectedProjectData.current_step,
                    indicators: updatedIndicatorsData
                });

                // Fetch and update project data after updating requirements
                const updatedProjects = await ProjectService.fetchProjectById(selectedProjectData.id);
                // updateProjectData(updatedProjects);
                const updatedProject = updatedProjects
                if (updatedProject) {
                    setSelectedProjectData(updatedProject);
                }
            } catch (error) {
                console.error('Failed to update requirement states:', error);
            }
        }
    }


    const onClickBack = async () => {
        setIsLoading(true);
        await updateRequirementStates();
        props.handleBack();
        setIsLoading(false);
    };


    const onClickNext = async () => {
        setIsLoading(true);
        await updateRequirementStates();
        props.handleNext();
        setIsLoading(false);
    };

    useEffect(() => {
        if (isShowIndicatorWeighting) {
            props.setIsMiniStepActive(true);
        }
        else
            props.setIsMiniStepActive(false);
    }, [isShowIndicatorWeighting]);


    return (
        <Fragment>
            {isLoading ? (
                <CustomLoadingSpinner />
            ) : isShowIndicatorWeighting ? (
                <Fragment>
                    <IndicatorWeighting className="weighting-container" indicatorData={indicatorData} setIndicatorData={setIndicatorData} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', }}>
                        <Button onClick={() => { updateIndicatorsState(); setIsShowIndicatorWeighting(false) }} sx={{
                            width: "100px",
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            borderRadius: '5px ',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}>Save</Button>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <RequirementWeighting className="weighting-container" requirementData={requirementData} setRequirementData={setRequirementData} setIsShowIndicatorWeighting={() => { setIsShowIndicatorWeighting(true); updateRequirementStates(); props.setIsMiniStepActive(true); }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                        <Button onClick={onClickBack} sx={{
                            width: "100px",
                            bgcolor: '#ffffff',
                            color: '#16697A',
                            textTransform: 'none',
                            borderRadius: '5px',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}>Back</Button>
                        <Button onClick={onClickNext} sx={{
                            width: "100px",
                            bgcolor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            borderRadius: '5px ',
                            '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                        }}>Next</Button>
                    </div>
                </Fragment>
            )}
        </Fragment>

    );
};

export default CustomCard(Weighting);
