import React, { useContext, useEffect, useState } from 'react';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { ProjectService } from '../../utils/ApiDataHandler';
import CategoriesList from './CategoriesList';
import { Box, Divider } from '@mui/material';
import { projectContext } from '../../hooks/customHooks/Context';

const CategoriesComponent = ({ selectedCategory, setSelectedCategory, updateRequirementStates, setIsRequirementLoading }) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { setTotalCategories, setCategoriesData } = useContext(projectContext);
    const [activeCategory, setActiveCategory] = useState(selectedCategory);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                const response = await ProjectService.fetchCategories();
                if (response && response.length > 0) {
                    setCategories(response);
                    setCategoriesData(response);
                    setTotalCategories(response.length);

                    if (!selectedCategory) {
                        setActiveCategory(1);
                        setSelectedCategory(1);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategories();
    }, [setSelectedCategory, setCategoriesData, setTotalCategories, selectedCategory]);

    useEffect(() => {
        if (selectedCategory !== activeCategory) {
            setActiveCategory(selectedCategory);
        }
    }, [selectedCategory, activeCategory]);

    const handleOnClickCategory = async (id) => {
        setIsRequirementLoading(true);
        await updateRequirementStates();
        setIsRequirementLoading(false);
        setActiveCategory(id);
        setSelectedCategory(id);
    };

    return isLoading ? (
        <CustomLoadingSpinner />
    ) : (
        <Box display="flex" alignItems="stretch" >
            <Box flexGrow={1} sx={{ width: '35%' }}>
                <CategoriesList
                    categories={categories}
                    activeCategory={activeCategory}
                    onclickCategoryHandler={handleOnClickCategory}
                />
            </Box>
            <Divider sx={{ borderColor: '#ffffff', minHeight: '42vh', m: '10px' }} orientation="vertical" flexItem className="vertical-divider" />
        </Box>
    );
};

export default CategoriesComponent;
