import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { chartColors } from "../utils/ProjectDataHandler";
import { ProjectService } from '../utils/ApiDataHandler';

function CustomStackedBarChart({ seriesData, countryList, projectId, customWidth, isPaidUser }) {
    const [countriesData, setCountriesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await ProjectService.fetchCountries();
                setCountriesData(result);
            } catch (error) {
                console.error("Error fetching countries data:", error);
            }
        };
        fetchData();
    }, []);

    const getChartDimensions = () => {
        const width = Math.max(dimensions.width * 0.6, 300);
        const height = Math.max(dimensions.height * 0.5, 400);
        return { width, height };
    };

    const { width, height } = getChartDimensions();

    const normalizedSeriesData = seriesData.map(series => ({
        ...series,
        data: series.data.map(value => (value === null || value === undefined || value === "" ? 0 : parseFloat(value))),
    }));

    // Calculate percentages based on sum of maximum values
    const calculatePercentages = (data) => {
        // Find maximum value for each series
        const maxValues = data.map(series =>
            Math.max(...series.data)
        );

        // Calculate total of maximum values
        const totalMaxValue = maxValues.reduce((sum, value) => sum + value, 0);
        data.forEach((series, index) => {
        });


        // Convert all values to percentages based on total maximum value
        return data.map(series => ({
            name: series.name,
            data: series.data.map(value =>
                ((value / totalMaxValue) * 100).toFixed(2)
            )
        }));
    };

    const countryIdToDataMap = countriesData.reduce((map, country) => {
        map[country.id] = { name: country.name, code: country.countrycode };
        return map;
    }, {});

    const sortedData = countryList
        .map((countryId, index) => {
            const countryValues = normalizedSeriesData.map(series => series.data[index] || 0);
            const totalValue = countryValues.reduce((sum, value) => sum + value, 0);

            return {
                countryId,
                countryCode: countryIdToDataMap[countryId]?.code || countryId,
                countryName: countryIdToDataMap[countryId]?.name || "Unknown",
                totalValue,
                data: countryValues,
            };
        })
        .sort((a, b) => b.totalValue - a.totalValue)
        .slice(0, 20);

    const sortedCountryNames = sortedData.map(item => item.countryName);
    const sortedSeriesData = normalizedSeriesData.map(series => ({
        ...series,
        data: sortedData.map(item => item.data[normalizedSeriesData.indexOf(series)]),
    }));

    // Convert to percentages
    const percentageSeriesData = calculatePercentages(sortedSeriesData);
    useEffect(() => {
        if (seriesData.length && countryList.length && countriesData.length) {
            const payload = {
                project_id: projectId,
                best_fitting_result: {
                    labels: sortedCountryNames,
                    datasets: percentageSeriesData.map(series => ({
                        label: series.name,
                        data: series.data,
                    })),
                    colors: chartColors.slice(0, percentageSeriesData.length),
                }
            };
            ProjectService.UpdateProjectResult(payload).catch(error => {
                console.error("Error sending data to API:", error);
            });
        }
    }, [percentageSeriesData, sortedCountryNames, projectId, countriesData]);

    if (loading || !seriesData.length || !countryList.length || !countriesData.length) {
        return <div>Preparing your chart...</div>;
    }


    return (
        <React.Fragment>
            <div className="container-fluid" style={{ display: 'flex', alignItems: 'center', width: '100%', filter: isPaidUser ? 'none' : 'blur(4px)' }}>

                <Chart

                    type="bar"
                    width={customWidth ? customWidth : width}
                    height={height}
                    series={percentageSeriesData}
                    options={{
                        colors: chartColors,
                        chart: {
                            type: 'bar',
                            stacked: true,
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false
                                }
                            },

                            events: {
                                dataPointMouseEnter: function (event, chartContext, config) {
                                    const seriesIndex = config.seriesIndex;
                                    const bars = document.querySelectorAll(`.apexcharts-series[rel="${seriesIndex + 1}"] path`);
                                    document.querySelectorAll('.apexcharts-series path').forEach(bar => {
                                        bar.style.opacity = '0.3';
                                    });
                                    bars.forEach(bar => {
                                        bar.style.opacity = '1';
                                    });
                                },
                                dataPointMouseLeave: function () {
                                    document.querySelectorAll('.apexcharts-series path').forEach(bar => {
                                        bar.style.opacity = '1';
                                    });
                                }
                            }
                        },
                        xaxis: {
                            categories: sortedCountryNames,
                        },
                        yaxis: {
                            min: 0,
                            max: 100,
                            tickAmount: 3,
                            labels: {
                                formatter: function (value) {
                                    return value.toFixed(0) + '%';
                                }
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 0,
                                dataLabels: {
                                    total: {
                                        enabled: true,
                                        offsetX: 0,

                                    }
                                }
                            },
                        },

                        dataLabels: {
                            enabled: false,
                            formatter: function (value) {
                                return value > 3 ? value.toFixed(1) + '%' : '';
                            },

                        },
                        legend: {
                            position: 'top',
                            show: false,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value) {
                                    return value.toFixed(2) + '%';
                                }
                            },
                            x: {
                                formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                    return sortedCountryNames[dataPointIndex];
                                },
                            },
                        },
                        fill: {
                            opacity: 1,
                        },
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default CustomStackedBarChart;