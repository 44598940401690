import { Button, Slider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import CustomStackedBarChart from '../../customComponents/CustomStackedBarChart';
import { ProjectService } from '../../utils/ApiDataHandler';
import { projectContext, cardContext } from '../../hooks/customHooks/Context';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import "./RequirementWeighting.css";
import { chartColors, getNameByRequirementId } from "../../utils/ProjectDataHandler";


const EvaluationPage = (props) => {
    const { selectedProjectData } = useContext(projectContext);
    const [evaluationData, setEvaluationData] = useState(null);
    const [seriesData, setSeriesData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [isWorldwide, setIsWorldwide] = useState(false);
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isPaidUser, setIsPaidUser] = useState(true);

    useEffect(() => {
        setHeading('Which region fits your requirement best? ');
        setDescription('The following evaluation shows the most suitable regions worldwide. Hover over the bar chart or stacked elements to view your selected requirements and their respective percentages.');
        setCardWidth("100%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    const downloadReport = async (projectId) => {
        setIsDownloading(true);
        try {
            await ProjectService.downloadReport(projectId);
        } catch (error) {
            console.error('Failed to download the report:', error);
        } finally {
            setIsDownloading(false);
        }

    };

    const onClickBack = () => {
        props.handleBack();
    };

    const onClickNext = () => {
        props.handleNext();
    };

    const getEvaluation = async (selectedProjectData) => {
        try {
            const result = await ProjectService.fetchEvaluation(selectedProjectData.id);
            setEvaluationData(result);
            processEvaluationData(result, isWorldwide);
            setIsPaidUser(true);
        } catch (error) {
            console.error('Error fetching evaluation:', error);
            if (error?.message && error?.message === "Only paid users can evaluate projects. Please make a payment to access this feature.") {
                setIsPaidUser(false);
            }
        }
    };
    const processEvaluationData = (data, isWorldwide) => {

        const series = [];
        const countries = [];

        // Step 1: Calculate total weighting

        const totalWeighting = data.project_requirements.reduce((sum, req) => sum + parseFloat(req.weighting), 0);

        if (!isWorldwide) {
            const allCountryData = data.all_country_result;
            Object.keys(allCountryData).forEach((stackKey) => {
                const stackData = allCountryData[stackKey];


                // Step 2: Find the requirement by its id
                const requirement = data.project_requirements.find(req => req.requirement_id === Number(stackKey));

                // Step 3: Calculate the percentage of the requirement's weighting and round to two decimal places
                const weightingPercentage = (parseFloat(requirement.weighting) / totalWeighting).toFixed(2);
                const weightedData = Object.values(allCountryData[stackKey]).map(value => (value * weightingPercentage).toFixed(2));

                // Step 4: Initialize the weightedData array for IDs 1-200
                /* const weightedData = [];
 
                 // Iterate over the range 1 to 200 (representing the expected IDs)
                 for (let i = 1; i <= 200; i++) {
                     // If the current ID exists in stackData, apply the weighting
                     if (stackData.hasOwnProperty(i.toString())) {
                         weightedData.push((stackData[i.toString()] * weightingPercentage).toFixed(2));
                     } else {
                         // If the ID is missing, add a zero
                         weightedData.push("0.00");
                     }
                 }*/
                series.push({
                    name: getNameByRequirementId(data.project_requirements, stackKey),
                    data: weightedData,
                });

                if (countries.length === 0) {
                    countries.push(...Object.keys(allCountryData[stackKey]));
                }
            });

        }
        /*else {

            const selectedData = data.before_applied_percentage_requirments_value_selected_country;

            Object.keys(selectedData).forEach((stackKey) => {
                const stackData = selectedData[stackKey];
                const requirement = data.project_requirements.find(req => req.requirement_id === Number(stackKey));
                const weightingPercentage = (parseFloat(requirement.weighting) / totalWeighting).toFixed(2);
                const weightedData = Object.values(selectedData[stackKey]).map(value => (value * weightingPercentage).toFixed(2));
                series.push({
                    name: getNameByRequirementId(data.project_requirements, stackKey),
                    data: weightedData,
                });

                if (countries.length === 0) {
                    countries.push(...Object.keys(selectedData[stackKey]));
                }
            });
        } */
        setSeriesData(series);
        setCountryList([...new Set(countries)]);
    };

    const handleToggle = () => {
        setIsWorldwide(!isWorldwide);
        if (evaluationData) {
            processEvaluationData(evaluationData, !isWorldwide);
        }
    };

    useEffect(() => {
        if (selectedProjectData?.id) {
            getEvaluation(selectedProjectData);
        }
    }, [selectedProjectData]);

    return (
        <div>
            <h3 style={{ color: '#16697A' }}>Top Regions Based on Your Requirements</h3>
            <Grid container ta sx={{ minHeight: "48vh", justifyContent: 'space-around' }}>
                {/*{selectedProjectData?.requirements && (
                    <Grid item xs={4} sx={{ width: '40%', fontSize: '12px' }} className="requirement-slider">
                        {Object.values(selectedProjectData.requirements).sort((a, b) => a.requirement_id - b.requirement_id)
                            .map((requirement, index) => {
                                return (
                                    <Grid container key={requirement.requirement_id} sx={{ marginBottom: 2 }}>
                                        <Grid
                                            item
                                            xs={5}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                textAlign: 'left',
                                                height: 30,
                                            }}
                                            gap={2}
                                        >
                                            <Brightness1Icon
                                                sx={{
                                                    color: chartColors[index % chartColors.length],
                                                    textAlign: 'left',
                                                    height: 16,
                                                }}
                                            />
                                            {requirement.requirement_name}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                )}*/}

                {evaluationData ? (
                    <Grid item sx={{ minHeight: "48vh", maxWidth: "100%", overflowX: 'auto' }}>
                        { /* <Button
                            onClick={handleToggle}
                            sx={{
                                width: '50%',
                                bgcolor: '#FFA62B',
                                color: '#313638',
                                textTransform: 'none',
                                borderRadius: '5px',
                                marginBottom: '10px',
                                '&:hover': { bgcolor: '#FFA62B', color: '#313638' },
                            }}
                        >
                            {isWorldwide ? 'Selected Countries' : 'Best fitting regions worldwirde'}
                        </Button>*/}
                        <CustomStackedBarChart seriesData={seriesData} countryList={countryList} projectId={selectedProjectData.id} customWidth={1000} isPaidUser={isPaidUser} />
                        <p>{evaluationData.name}</p>
                    </Grid>
                ) : (
                    <Grid item xs={6}>
                        {isPaidUser ? (
                            <p>Loading evaluation data...</p>
                        ) : (
                            <Grid item xs={6} style={{
                                textAlign: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', background: '#f9f9f9', height: '100%', width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Typography variant="h6" color="textSecondary">
                                    🔒 This evaluation data is available for paid users only.
                                </Typography>
                                <Typography variant="body2" style={{ marginTop: '10px', color: '#555' }}>
                                    Upgrade to a premium plan to unlock full insights!
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                <Button variant="contained" onClick={() => downloadReport(selectedProjectData.id)} disabled={!isPaidUser || selectedProjectData.current_step <= 4 || isDownloading} className="download-button" sx={{ width: '200px', ml: 2, bgcolor: '#FFA62B', color: '#313638', textTransform: 'none', '&:hover': { bgcolor: '#FFA62B', color: '#ffffff', } }}> {!isPaidUser && "🔒"} {isDownloading ? 'Downloading...' : 'Download Report'}</Button>
                <Button
                    onClick={onClickBack}
                    sx={{
                        width: '100px',
                        bgcolor: '#ffffff',
                        color: '#16697A',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={onClickNext}
                    sx={{
                        width: '100px',
                        bgcolor: '#16697A',
                        color: '#ffffff',
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                    }}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default CustomCard(EvaluationPage);
