import React from 'react';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import { Button, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './CategoriesList.css';

const CategoriesList = ({ categories, activeCategory, onclickCategoryHandler }) => {

    const sortedCategories = [...categories].sort((a, b) => a.id - b.id);

    return (
        <Grid className="category-button-container">
            <Stack
                direction="column"
                spacing={0}
                className="category-button-stack"
            >
                {sortedCategories.map((category) => (
                    <Button
                        className="category-button"
                        style={{
                            height: '58px',
                            fontSize: '16px',
                            fontWeight: category.id === activeCategory ? 'bold' : 'normal',
                            textTransform: 'none',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            whiteSpace: 'normal',
                            textAlign: 'left',
                            backgroundColor: category.id === activeCategory ? '#ffffff' : 'transparent',
                            '&:hover': {
                                backgroundColor: category.id === activeCategory ? '#ffffff' : '#ffffff',
                            },
                            color: category.id === activeCategory ? '#16697A' : '#489FB5',
                        }}
                        key={category.id}
                        onClick={() => {
                            onclickCategoryHandler(category.id);
                        }}
                    >
                        {category.name}
                    </Button>
                ))}
            </Stack>
        </Grid >
    );
};

export default CategoriesList;
