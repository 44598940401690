import React, { Fragment, useContext, useEffect } from "react";
import { Button, Slider } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Grid from "@mui/material/Grid2";
import { CustomPieChart } from "../../customComponents/CustomPieChart";
import { chartColors } from "../../utils/ProjectDataHandler";
import { cardContext } from '../../hooks/customHooks/Context';
import "./IndicatorWeighting.css";

const IndicatorWeighting = (props) => {
    const indicatorData = props.indicatorData;
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    useEffect(() => {
        setHeading('Which indicator is how meanningful in this analysis? ');
        setDescription('The following indicators weights have been assigned by scaling experts. You can make adjustments by changing the numerical weights for each indicator. ');
        setCardWidth("100%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);
    const groupByRequirement = (data) => {
        return data.reduce((acc, curr) => {
            const { requirement_name } = curr;
            if (!acc[requirement_name]) {
                acc[requirement_name] = [];
            }
            acc[requirement_name].push(curr);
            return acc;
        }, {});
    };

    const handleSliderChange = (indicatorId, newValue) => {
        const updatedIndicator = indicatorData.map((indicator) =>
            indicator.indicator_id === indicatorId
                ? { ...indicator, weighting: newValue }
                : indicator
        );
        props.setIndicatorData(updatedIndicator);
    };

    const groupedIndicators = groupByRequirement(indicatorData);
    const totalWeights = {};
    Object.keys(groupedIndicators).forEach((requirement) => {
        totalWeights[requirement] = groupedIndicators[requirement].reduce((sum, item) => sum + item.weighting, 0);
    });

    return (
        <Fragment>
            <Grid container spacing={1} sx={{ minHeight: "48vh", }}>
                <Grid container size={12} spacing={1} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#16697A'
                }}>
                    <Grid size={6} >
                    </Grid>
                    <Grid size={3} sx={{
                        textAlign: 'center',
                        color: '#16697A'
                    }}>
                        Weighting
                    </Grid>
                    <Grid size={2.5} sx={{
                        textAlign: 'center',
                        color: "#16697A",

                    }}>
                        Percentage
                    </Grid>
                </Grid>
                <Grid container size={12} className="indicator-list">
                    {Object.keys(groupedIndicators).map((requirementName, reqIndex) => (
                        <Fragment key={requirementName}>
                            <Grid size={6} sx={{ fontWeight: 'bold', marginTop: 0, color: '#16697A', alignItems: "center", height: 'auto', textAlign: 'left', display: "flex", flexDirection: "row" }}>
                                <Brightness1Icon
                                    sx={{
                                        color: chartColors[(reqIndex) % chartColors.length],
                                        paddingRight: 1,
                                        height: 17,
                                    }}
                                />
                                {requirementName}
                            </Grid>
                            {/* Column Headers */}
                            <Grid container size={12} spacing={1} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'medium',
                                fontSize: '15px',
                                color: '#16697A', marginBottom: '1.2%', marginTop: '-4%'
                            }}>

                            </Grid>

                            <Grid container size={12} spacing={1} sx={{ display: "flex", alignItems: "stretch" }}>
                                <Grid size={11}>
                                    {groupedIndicators[requirementName].map((indicator, index) => (
                                        <Grid container size={12} key={indicator.id} spacing={1} justifyContent="space-between">
                                            <Grid size={6} sx={{ display: "flex", flexDirection: "row", textAlign: 'left', paddingLeft: '30px', color: '#489FB5', paddingBottom: '5px' }}>
                                                {indicator.indicator_name}
                                            </Grid>
                                            <Grid size={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Slider
                                                    aria-label="Default"
                                                    value={indicator.weighting}
                                                    step={1}
                                                    min={1}
                                                    max={10}
                                                    valueLabelDisplay="on"
                                                    onChange={(event, newValue) => {
                                                        handleSliderChange(indicator.indicator_id, newValue);
                                                    }}
                                                    sx={{
                                                        "& .MuiSlider-thumb": {
                                                            width: 15,
                                                            height: 15,
                                                            color: "#16697A",
                                                        },
                                                        "& .MuiSlider-track": {
                                                            color: "#489FB5",
                                                        },
                                                        "& .MuiSlider-rail": {
                                                            color: "#82C0CC",
                                                        },
                                                        "& .Mui-active": {
                                                            width: 18,
                                                            height: 18,
                                                            color: "#16697A",
                                                        },
                                                        "& .MuiSlider-valueLabel": {
                                                            backgroundColor: "transparent",
                                                            color: "#16697A",
                                                            fontSize: "12px",
                                                            borderRadius: "4px",
                                                            marginTop: "15px ",
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid size={1.25} sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontWeight: "bold",
                                                color: "#489FB5",
                                                backgroundColor: "#82c0cc33",
                                                flexWrap: 'wrap',
                                                alignContent: 'start',
                                                paddingTop: '1%',
                                                paddingBottom: '1%',
                                                borderRadius: '0px'
                                            }}>
                                                {((indicator.weighting / totalWeights[requirementName]) * 100).toFixed(0)
                                                } %
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </Grid>
        </Fragment >
    );
};

export default IndicatorWeighting;