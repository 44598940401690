import React from 'react';
import "./Footer.css";

function Footer() {
    return (
        <div className="footer">
            {/* <div className="footer-container">
                <div className="footer-logo">
                    <span className="tech">tech</span><span className="scaler">Scaler</span>
                </div>
                <div className="footer-links">
                    <div className="footer-column">
                        <h4>Lorem ipsum</h4>
                        <a href="#">Company</a>
                        <a href="#">Contact</a>
                        <a href="#">Legal notice</a>
                        <a href="#">Privacy policy</a>
                    </div>
                    <div className="footer-column">
                        <h4>Lorem ipsum</h4>
                        <a href="#">Company</a>
                        <a href="#">Contact</a>
                        <a href="#">Legal notice</a>
                        <a href="#">Privacy policy</a>
                    </div>
                    <div className="footer-column">
                        <h4>Lorem ipsum</h4>
                        <a href="#">Company</a>
                        <a href="#">Contact</a>
                        <a href="#">Legal notice</a>
                        <a href="#">Privacy policy</a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>xScaler ©2024</p>
                </div>
            </div>*/}
        </div>
    );
}

export default Footer;
