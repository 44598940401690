// API Configuration
const API_BASE_URL = 'https://da-platform-backend-stage-qa98q.ondigitalocean.app/api/v1';

// Token Management
const getToken = () => localStorage.getItem('access_token');
const setToken = (token) => localStorage.setItem('access_token', token);
const removeToken = () => localStorage.removeItem('access_token');

const getPaymentStatus = () => JSON.parse(localStorage.getItem('payment_status'));
const setPaymentStatus = (status) => localStorage.setItem('payment_status', JSON.stringify(status));
const removePaymentStatus = () => localStorage.removeItem('payment_status');

// Reusable API Request Function
const apiRequest = async (endpoint, method = 'GET', body = null, isBinary = false) => {
    const headers = {
        'Content-Type': isBinary ? 'application/octet-stream' : 'application/json',
        'Authorization': `Bearer ${getToken()}`,
    };

    const config = {
        method,
        headers,
    };

    if (body && !isBinary) {
        config.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`, config);

        if (isBinary) {
            if (!response.ok) {
                throw new Error(`Failed to fetch binary data: ${response.statusText}`);
            }
            return await response.blob();
        }

        const data = await response.json();
        if (!response.ok) {
            // return data;
            throw new Error(data.message || `Request failed with status ${response.status}`);
        }
        return data;
    } catch (error) {
        console.error(`Error with ${method} request to ${endpoint}:`, error);
        throw error;
    }
};


export const AuthService = {
    login: async (email, password) => {
        try {
            const data = await apiRequest('/auth/login', 'POST', { email, password });

            if (data.success) {
                setToken(data.data.access_token);
                setPaymentStatus(data.data.payment_status);
            }
            return data;
        } catch (error) {
            console.error(`Login failed: ${error.message}`);
            throw error;
        }
    },

    signup: async (signupData) => {
        return await apiRequest('/auth/signup', 'POST', signupData);
    },

    logout: () => {
        removeToken();
        removePaymentStatus();
    },

    getPaymentStatus: () => {
        return getPaymentStatus();
    },


    fetchUserDetails: async () => {
        return await apiRequest('/user/details');
    },
    forgotPassword: async (email, confirmEmail) => {
        try {
            const data = await apiRequest(`/user/forgot-password?email=${encodeURIComponent(email)}&confirm_email=${encodeURIComponent(confirmEmail)}`, 'POST');
            return data;
        } catch (error) {
            console.error(`Forgot password failed: ${error.message}`);
            throw error;
        }
    },
    UpdatePassword: async (current_password, password, password_confirmation) => {
        try {
            const data = await apiRequest(`/user/update-password?current_password=${encodeURIComponent(current_password)}&password=${encodeURIComponent(password)}&password_confirmation=${encodeURIComponent(password_confirmation)}`, 'POST');
            return data;
        } catch (error) {
            console.error(`Forgot password failed: ${error.message}`);
            throw error;
        }
    },

};


export const ProjectService = {
    createProject: async (projectData) => {
        const result = await apiRequest('/projects', 'POST', projectData);
        return result.data.id;
    },

    updateProject: async (projectData, projectId) => {
        const params = new URLSearchParams(projectData).toString();
        const result = await apiRequest(`/projects/${projectId}`, 'PUT', projectData);
        return result.data;
    },

    updateProjectRequirements: async (requirements) => {
        const result = await apiRequest('/project-requirements', 'POST', requirements);
        return result.data.id;
    },

    updateProjectRequirement: async (requirementID, requirement) => {
        const params = new URLSearchParams(requirement).toString();
        const result = await apiRequest(`/project-requirements/${requirementID}?${params}`, 'PUT');
        return result.data.id;
    },

    deleteProjectRequirements: async (requirementID) => {
        try {
            const result = await apiRequest(`/project-requirements/${requirementID}`, 'DELETE');
            return result.data.id;
        } catch (error) {
            console.error('Error deleting project requirement:', error);
            throw error;
        }
    },

    updateProjectIndicators: async (indicators) => {
        const result = await apiRequest('/project-indicators', 'POST', indicators);
        return result.data.id;
    },

    updateProjectCountries: async (countries) => {
        const result = await apiRequest('/project-countries', 'POST', countries);
        return result.data.id;
    },

    fetchProjects: async () => {
        const data = await apiRequest('/projects');
        return data.data;
    },
    fetchProjectById: async (project_id) => {
        const result = await apiRequest(`/projects/${project_id}`);
        return result.data;
    },

    fetchFramingData: async () => {
        return await ProjectService.fetchProjects();
    },

    fetchSectors: async () => {
        const response = await apiRequest('/sectors');
        return response.data;
    },
    fetchCategories: async () => {
        const response = await apiRequest('/categories');
        return response.data;
    },
    fetchCategoriesById: async (categoryId) => {
        try {
            const response = await apiRequest(`/categories/${categoryId}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch category:', error);
            throw error;
        }
    },
    fetchRequirementsByID: async (reqID) => {
        try {
            const response = await apiRequest(`/requirements/${reqID}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch category:', error);
            throw error;
        }
    },

    fetchRequirementNameByID: async (reqID) => {
        try {
            const response = await apiRequest(`/requirements/${reqID}`); // Correct URL format
            return response.data.name;
        } catch (error) {
            console.error('Failed to fetch category:', error);
            throw error; // Re-throw the error if you need to handle it elsewhere
        }
    },


    fetchCountries: async () => {
        const response = await apiRequest('/countries');
        return response.data;
    },
    addTeamMember: async (projectId, firstName, lastName, email) => {
        const endpoint = `/project/add-member?project_id=${projectId}&first_name=${firstName}&last_name=${lastName}&email=${email}`;
        try {
            const result = await apiRequest(endpoint, 'POST');
            return result.data; // Assuming the API returns data
        } catch (error) {
            console.error('Error adding team member:', error);
            throw error;
        }
    },
    fetchUserProfile: async () => {
        try {
            const response = await apiRequest('/user/profile');
            return response; // Return the full response
        } catch (error) {
            console.error('Error fetching user profile:', error);
            throw error; // Throw the error to be caught in the calling function
        }
    },
    fetchEvaluation: async (evalID) => {
        try {
            const response = await apiRequest(`/evaluate-project/${evalID}`, 'POST');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch evaluation:', error.message);
            throw error;
        }
    },
    updateTeamMembers: async (projectId, updatedMembers) => {
        try {
            const result = await apiRequest(`/project/add-member?project_id=${projectId}`, 'POST', { members: updatedMembers });
            return result.data;
        } catch (error) {
            console.error('Error updating team members:', error);
            throw error;
        }
    },
    updateUserProfile: async (userData) => {
        try {
            const result = await apiRequest('/user/update-profile', 'POST', userData);
            return result;
        } catch (error) {
            console.error("Error updating user profile:", error);
            throw error;
        }
    },

    fetchCompanyDetails: async () => {
        try {
            const response = await apiRequest('/get-company-details');
            return response;
        } catch (error) {
            console.error('Error fetching company details:', error);
            throw error;
        }
    },

    submitCompanyProfile: async (companyData) => {
        try {
            const result = await apiRequest('/add-company-info', 'POST', companyData);
            return result.data;
        } catch (error) {
            console.error('Error submitting company profile:', error);
            throw error;
        }
    },


    updateCompanyProfile: async (companyData) => {
        try {
            const result = await apiRequest('/update-company-info', 'PUT', companyData);
            return result;
        } catch (error) {
            console.error('Error updating company profile:', error);
            throw error;
        }
    },
    fetchRevenues: async () => {
        const response = await apiRequest('/revenues');
        return response.data;
    },

    fetchPositions: async () => {
        const response = await apiRequest('/positions');
        return response.data;
    },

    fetchIndustries: async () => {
        const response = await apiRequest('/industries');
        return response.data;
    },
    fetchSalutations: async () => {
        return await apiRequest('/salutations');
    },
    fetchOrganizationType: async () => {
        return await apiRequest('/organization-types');
    },
    UpdateProjectResult: async (payload) => {
        try {
            const result = await apiRequest(`/project/update-project-result`, 'POST', payload);
            return result.data;
        } catch (error) {
            console.error('Error updating project result:', error);
            throw error;
        }
    },

    needHelp: async (projectId, message, currentStep) => {
        try {
            const result = await apiRequest(`/project/help?project_id=${projectId}&current_step=${currentStep}&message=${message}`, 'POST');
            return result;
        } catch (error) {
            console.error('message is not sent:', error);
            throw error;
        }
    },

    downloadReport: async (projectId) => {
        try {
            const blob = await apiRequest(`/download-report/${projectId}`, 'GET', null, true);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-${projectId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return { success: true };
        } catch (error) {
            console.error('Error downloading the report:', error);
            throw error;
        }
    },




};

