import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Button,
    Modal,
    Box,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "./IndicatorPopup.css";
import CustomMinMaxSwitch from "../../customComponents/CustomMinMaxSwitch";
import { ProjectService } from "../../utils/ApiDataHandler";
import { projectContext } from "../../hooks/customHooks/Context";
import { getCategoryName } from '../../utils/ProjectDataHandler'
import { parseMarkdownToJSX } from '../../customComponents/formatShortInfo';


function IndicatorPopup({ open, onClose, requirementData, indicatorData, categoriesData, isLoopActive, setIsLoopActive }) {
    const { selectedProjectData, setSelectedProjectData, updateProjectData } = useContext(projectContext);
    const [isLoading, setIsLoading] = useState(false);
    const [indicators, setIndicators] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState({});
    const [isMinMaxSwitchEnabled, setIsMinMaxSwitchEnabled] = useState({});
    const categoryNameMap = getCategoryName(categoriesData);

    const getIndicatorsHandler = useCallback(async () => {
        try {
            const requirement = await ProjectService.fetchRequirementsByID(requirementData.requirement_id);

            const filteredIndicators = requirement.indicators.map((indicator) => ({
                id: indicator.id,
                name: indicator.name,
                description: indicator.description || "",
                key: indicator.id,
                datasource_name: indicator.datasource_name,
                is_predefined: indicator.is_predefined,
                requirement_id: requirement.id,
                weighting: indicator.weighting ? indicator.weighting : 1,
                shortInfo: indicator.description || "No additional info",
            }));
            setIndicators(filteredIndicators);
        } catch (error) {
            console.error('Error fetching requirement:', error);
        }
    }, [requirementData]);

    useEffect(() => {
        if (open) {
            getIndicatorsHandler();
        }
    }, [open, getIndicatorsHandler]);

    useEffect(() => {
        if (requirementData) {
            setIsMinMaxSwitchEnabled((prevState) => ({
                ...prevState,
                [requirementData.id]: requirementData.priority === 1,
            }));
        }
    }, [requirementData]);

    const onMinMaxSwitchChange = (requirementId, isChecked) => {
        setIsMinMaxSwitchEnabled((prevState) => ({
            ...prevState,
            [requirementId]: isChecked,
        }));
    };

    useEffect(() => {
        const initialIndicators = selectedProjectData.indicators || [];
        const formattedIndicators = initialIndicators.reduce((acc, indicator) => {
            acc[indicator.indicator_id] = {
                requirement_id: indicator.requirement_id,
                selected: true,
                weighting: indicator.weighting
            };
            return acc;
        }, {});
        setSelectedIndicators(formattedIndicators);
    }, [selectedProjectData.indicators]);

    const onClickCheckBoxHandler = (indicatorId, requirementId) => {
        setSelectedIndicators((prevSelected) => ({
            ...prevSelected,
            [indicatorId]: {
                requirement_id: requirementId,
                selected: !prevSelected[indicatorId]?.selected,
            },
        }));
    };

    const toSentenceCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };


    const saveSelection = async () => {
        const formattedIndicators = Object.entries(selectedIndicators)
            .filter(([_, indicator]) => indicator.selected)
            .map(([indicatorId, indicator]) => ({
                indicator_id: Number(indicatorId),
                requirement_id: indicator.requirement_id,
                weighting: indicator.weighting ? indicator.weighting : 1,
            }));

        const payload = {
            project_id: selectedProjectData.id,
            current_step: 3,
            indicators: formattedIndicators,
        };

        const requirementPayload = {
            project_id: selectedProjectData.id,
            category_id: requirementData.category_id,
            requirement_id: requirementData.requirement_id,
            priority: isMinMaxSwitchEnabled[requirementData.id] ? 1 : 0,
        };

        try {
            setIsLoading(true);
            await ProjectService.updateProjectIndicators(payload);
            await ProjectService.updateProjectRequirement(requirementData.id, requirementPayload);

            const updatedProjects = await ProjectService.fetchProjectById(selectedProjectData.id);
            setSelectedProjectData(updatedProjects);
        } catch (error) {
            console.error("Error saving indicators:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveIndicatorsHandler = async () => {
        await saveSelection();
        setIsLoopActive(true)
        setTimeout(() => {
            onClose();
        }, 0);
    };

    const saveAndCloseHandler = async () => {
        await saveSelection();
        setIsLoopActive(false);
        setTimeout(() => {
            onClose();
        }, 0);
    };


    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        height: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <h2 style={{ color: "#1c5a73" }} id="modal-title">
                        {categoryNameMap[requirementData?.category_id] || "Category"} -{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {requirementData.requirement_name || "Unnamed Requirement"}
                        </span>
                    </h2>
                    <h5 style={{ color: "#1c5a73" }}>
                        How can your requirements be measured? The following indicators are selected to make the market requirement measurable. You can make adjustments by selecting or deselecting indicators.
                    </h5>
                    <CustomMinMaxSwitch
                        isChecked={isMinMaxSwitchEnabled[requirementData.id]}
                        onChange={onMinMaxSwitchChange}
                        requirementId={requirementData.id}
                    />
                    {isLoading ? (
                        <p>saving indicators...</p>
                    ) : (
                        <div style={{ maxHeight: "320px", overflowY: "auto" }}>
                            {indicators.map((indicator) => (
                                <Grid key={indicator.id} container size={12} alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: "#1c5a73" }}
                                                checked={!!selectedIndicators[indicator.id]?.selected}
                                                /*checked={indicator.is_predefined || !!selectedIndicators[indicator.id]?.selected}*/
                                                onChange={() => onClickCheckBoxHandler(indicator.id, indicator.requirement_id)}
                                            />
                                        }
                                        label={toSentenceCase(indicator.name)}
                                    />
                                    {indicator.shortInfo && (
                                        <Tooltip
                                            title={
                                                <div
                                                    style={{
                                                        backgroundColor: '#ffffff',
                                                        color: '#000000',
                                                        maxHeight: '40vh',
                                                        overflowY: 'auto',
                                                        whiteSpace: 'pre-wrap',
                                                        fontSize: '14px',
                                                        padding: '5px',
                                                        scrollbarWidth: 'thin',
                                                        borderRadius: '5px',
                                                        border: '1px solid #ccc',
                                                        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)'
                                                    }}
                                                >
                                                    {'Data source: ' + indicator.datasource_name}
                                                    <br />
                                                    {parseMarkdownToJSX(indicator.shortInfo)}
                                                </div>
                                            }
                                            PopperProps={{
                                                sx: {
                                                    '.MuiTooltip-tooltip': {
                                                        border: 'none',
                                                        backgroundColor: '#ffffff',
                                                        maxHeight: '40vh',
                                                        minWidth: '500px',
                                                    },
                                                },
                                            }}
                                        >
                                            <InfoOutlinedIcon sx={{ color: '#1c5a73', width: '15px', marginLeft: '8px', paddingBottom: '1%' }} />
                                        </Tooltip>

                                    )}
                                </Grid>
                            ))}
                            <div className="button-container">
                                <Button
                                    variant="contained"
                                    onClick={saveAndCloseHandler}
                                    disabled={isLoading}
                                    sx={{
                                        width: "100px",
                                        bgcolor: '#16697A',
                                        color: '#ffffff',
                                        textTransform: 'none',
                                        borderRadius: '5px',
                                        marginRight: '20px',
                                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' }
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={saveIndicatorsHandler}
                                    disabled={isLoading}
                                    sx={{
                                        width: "100px",
                                        bgcolor: '#16697A',
                                        color: '#ffffff',
                                        textTransform: 'none',
                                        borderRadius: '5px',
                                        '&:hover': { bgcolor: '#16697A', color: '#ffffff' }
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}

export default IndicatorPopup;
