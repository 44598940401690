import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css'; // Optional: Add your custom styles

const FAQ = () => {
    const faqData = [
        {
            category: 'General',
            questions: [
                {
                    question: 'What is TechScaler, and how does it work?',
                    answer: ' TechScaler is a decision-support platform that helps businesses make informed decisions about scaling into new markets by providing actionable insights from data analytics. ',
                },
                {
                    question: 'Who are the typical users of TechScaler?',
                    answer: 'Our platform caters to startups, SMEs, accelerators, and venture capital firms looking to streamline their market expansion strategies.',
                },
                {
                    question: 'What kind of insights can I expect from TechScaler?',
                    answer: 'TechScaler delivers insights on market potential, competition, regulatory requirements, customer demographics, and risk factors in target markets. ',
                },
                {
                    question: 'Does TechScaler support international market entry?',
                    answer: 'Yes, TechScaler is designed to assist with both domestic and international market scaling by providing region-specific data.',
                },
                {
                    question: 'How accurate is the information provided by TechScaler?',
                    answer:
                        'We ensure data accuracy of over 98% through rigorous validation and cross-referencing with reliable data sources. ',
                },
                {
                    question: 'Can TechScaler be used for sectors beyond technology?',
                    answer: 'Absolutely! TechScaler supports market scaling across various sectors, including healthcare, retail, manufacturing, and more. ',
                },
                {
                    question: 'How long does it take to see results using TechScaler?',
                    answer:
                        'The timeline depends on the complexity of your scaling objectives, but many users see actionable insights within weeks of using the platform. ',
                },
                {
                    question: 'Is there a free trial available?',
                    answer:
                        'We offer a limited free trial for users to explore the platform’s capabilities before committing to a subscription or credit package. ',
                },
                {
                    question: 'What makes TechScaler different from other market analysis tools?',
                    answer:
                        'TechScaler combines advanced data-driven decision analytics, user-friendly workflows, and a combined 80+ years in understanding the worldwide scaling of cleantech. We have worked and developed this tool with leading tech companies (Amazon, Google 50+ tech startups); universities (MIT, Oxford, Stanford, LSE);  financial institutions (World Bank, leading investment banks) and various consultancing firms. ',
                },
                {
                    question: 'How do I know if TechScaler is right for my business? ',
                    answer:
                        'If your business is exploring new markets or struggling with scaling challenges, TechScaler can provide the data-driven insights needed to make data-driven decisions. ',
                },

            ],
        },
        /* {
             category: 'Credit-Based Pricing Model',
             questions: [
                 {
                     question: 'What is the credit-based pricing model?',
                     answer: 'The credit-based pricing model allows you to purchase credits upfront, which you can use across various features such as market decisions, reports, and team access. It provides flexibility and transparency, ensuring you only pay for what you use. ',
                 },
 
             ],
         },*/
        {
            category: 'Using TechScaler',
            questions: [
                {
                    question: 'How do I set up an account with TechScaler?',
                    answer: ' Visit our website, click “Sign Up,” and follow the onboarding steps to create your account.',
                },
                {
                    question: 'What is the first step after signing up? ',
                    answer: 'Once signed up, you can select a pricing plan, purchase credits, and start exploring market opportunities through our intuitive dashboard. ',
                },
                {
                    question: 'Can I collaborate with my team on TechScaler?',
                    answer: 'Yes, team collaboration is supported, allowing multiple users to access and work on projects together.',
                },
                {
                    question: 'Is there a learning curve for new users?',
                    answer: 'TechScaler is designed to be user-friendly, and our onboarding tutorials ensure you’re up and running quickly. ',
                },
                {
                    question: 'Can I export reports generated on TechScaler? ',
                    answer: 'Yes, all reports and analyses can be exported in PDF, Excel, or CSV formats.',
                },
                {
                    question: 'How do I access historical data for a market?',
                    answer: 'Historical data is available under specific plans or can be accessed using credits.',
                },
                {
                    question: ' Can I use TechScaler on mobile devices?',
                    answer: ' Yes, TechScaler is optimized for both desktop and mobile devices for on-the-go access. ',
                },
                {
                    question: 'How often is the data updated?',
                    answer: 'Our platform updates data continuously to ensure accuracy and relevance.',
                },
                {
                    question: 'What industries are currently supported by TechScaler? ',
                    answer: 'TechScaler supports industries like technology, healthcare, retail, and more, with new sectors added regularly.',
                },

            ],
        },
        {
            category: 'Support and Resources ',
            questions: [
                {
                    question: 'Where can I find tutorials on using TechScaler? ',
                    answer: 'Our help center includes detailed tutorials, videos, and user guides to assist you.',
                },
                {
                    question: 'Is there a support team I can contact?',
                    answer: 'Yes, our support team is available via chat, email, and phone during business hours. ',
                },
                {
                    question: 'Do you offer 24/7 support? ',
                    answer: 'Premium users have access to priority 24/7 support. ',
                },
                {
                    question: 'What happens if I encounter a technical issue? ',
                    answer: 'Our support team resolves most technical issues within 24 hours.',
                },
                {
                    question: 'Are there webinars or live training sessions? ',
                    answer: 'Yes, we host regular webinars and live training sessions for users. ',
                },
                {
                    question: 'Can I request custom data reports? ',
                    answer: 'Enterprise users can request custom reports through our support team.',
                },
                {
                    question: 'How can I provide feedback about the platform?  ',
                    answer: 'Feedback can be submitted through the platform or by contacting our support team. ',
                },
                {
                    question: 'Are there case studies of successful TechScaler users? ',
                    answer: 'Yes, we showcase case studies and success stories on our website. ',
                },
                {
                    question: 'How do I stay updated about new features?  ',
                    answer: 'Subscribe to our newsletter or follow us on social media for updates on new features and releases.',
                },
                {
                    question: 'Can I request features that aren’t currently available? ',
                    answer: 'We welcome feature requests and consider them during our product development cycles.  ',
                },

            ],
        },

    ];

    return (
        <div className="faq-container">
            <Typography variant="h4" color='#16697A'>
                FAQ
            </Typography>
            {faqData.map((category, index) => (
                <div key={index}>
                    <Typography variant="h6" gutterBottom className="faq-category">
                        {category.category}
                    </Typography>
                    {category.questions.map((item, idx) => (
                        <Accordion key={idx} sx={{ boxShadow: 'none' }}                   >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: 'rgba(130, 192, 204, 0.2)', color: '#16697A' }}>
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography>{item.answer || 'Answer not provided yet.'}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
