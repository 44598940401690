import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export const parseMarkdownToJSX = (text, initiallyShowFull = false) => {
    const cleanedText = text
        .split('\n')  // Split into lines
        .map(line => line.trim()) // Trim extra spaces
        .join('\n');  // Join back as a string
    const parts = cleanedText.split(/\*\*(.*?)\*\*/g);

    const MarkdownContent = () => {
        const [showFull, setShowFull] = useState(initiallyShowFull);

        const definitionEndIndex = 3;
        const hasMoreContent = parts.slice(definitionEndIndex).length > 0;

        const renderParts = showFull ? parts : parts.slice(0, definitionEndIndex);

        return (
            <div style={{ padding: "5px" }}>
                {renderParts.map((part, index) => {
                    if (index % 2 === 1) {
                        return (
                            <strong
                                key={index}
                                style={{
                                    display: 'block',
                                    textAlign: 'left',
                                }}
                            >
                                {part}
                            </strong>
                        );
                    } else {
                        return (
                            <span
                                key={index}
                                style={{
                                    fontWeight: 400,
                                    display: 'block',
                                    textAlign: 'left',
                                    marginLeft: '20px',
                                }}
                            >
                                {part.trimStart()}
                            </span>
                        );
                    }
                })}

                {!showFull && hasMoreContent && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Link
                            onClick={() => setShowFull(true)}
                            sx={{
                                color: '#16697A',
                                textTransform: 'none',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                marginTop: '-20px',
                                display: 'inline-block',
                                '&:hover': { color: '#16697A', textDecoration: 'underline', },
                            }}
                        >
                            Load More...
                        </Link>
                    </Box>
                )}
            </div>
        );
    };

    return <MarkdownContent />;
};