export const categoryValidations = {
    1: [
        "Jane Richardson, Principal Administrator Pesticides, Biotechnology, Food Safety, Environment, Health and Safety Division",
        "Brian Love, CEO, AgScience Insights."
    ],
    2: [
        "Max Gruenig (President, Ecologic Institute, Washington DC): overall review",
        "Viviana Jimenez (Director, Anthesis Group): climate change, oceans",
        "Marc Forni (Lead Disaster Specialist, World Bank): mforni@worldbank.org: natural disasters",

    ],
    3: [
        "Valeria Perotti, Manager, 'Business Ready'-Department, World Bank",
        "Jose Antonio Mejia, Lead Specialist, 'Modernization of the State', Inter-American Development Bank. (Former Vice-President, National Institute of Statistics, Mexico.)",
        "Armando Aliu, Researcher, International Organisation for Migration.",
        "Denise Dias",
        "Laura Brix Newbury",
        "Paul Reynolds"

    ],
    4: [
        "Shlomo Angel, Professor of City Planning, MArron Institute, New York University.",
        "Jonathan Buonocuore, Assistant Professor, Environmental Health. (Former Research Scientist on Environmental Health at Harvard School of Public Health.)",
        "Marie-Claire Sodergren, Senior Economist, Data Production and Analysis Unit, Department of Statistics, International Labor Organization.",
    ],
    5: [
        "Dr Delia Rodrigo, Senior Regulatory Reform Consultant, World Bank.",
        "Gary Kalman, Executive Director, Transparency International US.",
        "Fatema Waheb (Political Affairs Officer, UN Security Council): conflict, security, sanctions",
        "Michael van Gelderen (Human Rights Officer at UN Human Rights): human rights.",
        "Tim Bittiger (Executive Director, Democracy Reporting International): governance, elections.",

    ],
    6: [
        "Prof Nicholas Ashford, Professor of Technology and Policy, Massachusetts Institute of Technology."
    ],
    7: [
        "Delia Rodrigo"
    ]
};