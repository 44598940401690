import { Box, Button, Divider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CustomCard } from '../../customComponents/CustomCard';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { cardContext, loginContext, projectContext } from '../../hooks/customHooks/Context';
import { ProjectService, AuthService } from '../../utils/ApiDataHandler';
import { getMostRecentProjectId } from '../../utils/ProjectDataHandler';
import Typography from '@mui/material/Typography';
import RecentProjects from './RecentProjects';
import "./OverviewPage.css";
import SelectedProjectDataPage from './SelectedProjectDataPage';


function OverviewPage() {
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const { setShowOverviewPage } = useContext(loginContext);
    const { projectData, updateProjectData, setSelectedProjectData } = useContext(projectContext);
    const [loading, setLoading] = useState(true);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [status, setStatus] = useState(false);


    const newProjectHandler = () => {
        updateProjectData([]);
        setShowOverviewPage(false);
        setSelectedProjectData({
            id: null,
            title: "",
            sector_id: null,
            sub_sector_id: null,
            decision_date: "",
            current_step: 1,
            is_inprogress: true,
            requirements: [],
            countries: [],
            teamMembers: [],
            indicators: [],
            created_at: "",
            updated_at: ""
        });

    }
    useEffect(() => {
        setHeading('Overview');
        setDescription('');
        setCardWidth("70%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await ProjectService.fetchProjects();
                updateProjectData(response);
                if (response === 'No projects found for this user.') {
                    newProjectHandler();
                    return;
                }

                const recentProjectId = getMostRecentProjectId(response);
                setActiveProjectId(recentProjectId);
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            } finally {
                setLoading(false);
            }
        };
        const fetchStatus = async () => {
            try {
                const statusResponse = await AuthService.getPaymentStatus();
                setStatus(statusResponse);
            } catch (error) {
                console.error("Failed to fetch status:", error);
                setStatus(false);
            }
        };
        getProjects();
        fetchStatus();
    }, [updateProjectData]);

    return (
        <Box sx={{ width: '100%' }} className="grid-container">
            {loading ? (
                <div className="loading-container">
                    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" height="100%">
                        <CustomLoadingSpinner loading={true} />
                        <Typography sx={{ ml: 2, color: '#16697A' }}>Loading your projects</Typography>
                    </Box>
                </div>
            ) : (
                <Grid container spacing={2} >
                    <Grid size={{ xs: 12, md: 12, lg: 3 }} className="project-grid">
                        <Grid size={{ xs: 12, lg: 3 }} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'left', ml: 2, color: '#16697A', marginBottom: '16px', fontWeight: 'bold' }} variant="h6" gutterBottom>Recent Projects</Typography>
                            <div className="recent-projects-container">
                                <RecentProjects
                                    projectData={projectData}
                                    activeProjectId={activeProjectId}
                                    setActiveProjectId={setActiveProjectId} />
                            </div>
                            <Tooltip
                                title={!status ? "🔒 Upgrade to premium to add a new project" : ""}
                                disableHoverListener={status}
                                PopperProps={{
                                    sx: {
                                        '.MuiTooltip-tooltip': { bgcolor: '#16697A' }
                                    }
                                }}
                            >
                                <Button
                                    className="new-project"
                                    sx={{
                                        opacity: status ? 1 : 0.5,
                                        ml: 2,
                                        bgcolor: '#16697A',
                                        color: '#ffffff',
                                        textTransform: 'none',
                                        border: '5px solid #ffffff',
                                        borderRadius: '5px',
                                        '&:hover': { bgcolor: '#16697A', color: '#ffffff', border: '3px solid #ffffff', },
                                    }}
                                    onClick={status ? newProjectHandler : undefined} // Only allow clicks if status is true
                                    aria-disabled={!status} // Use aria-disabled instead of disabled
                                >
                                    {!status && "🔒"} Add new Project
                                </Button>
                            </Tooltip>
                        </Grid>

                    </Grid>

                    <Divider sx={{ borderColor: '#ffffff' }} orientation="vertical" flexItem className="vertical-divider" />

                    <Grid size={{ xs: 12, md: 12, lg: 8 }}>
                        <SelectedProjectDataPage activeProjectId={activeProjectId} />
                    </Grid>
                </Grid>
            )
            }
        </Box >
    );
}

export default CustomCard(OverviewPage);
