import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Button } from '@mui/material';
import CustomLoadingSpinner from '../../customComponents/CustomLoadingSpinner';
import { cardContext, projectContext } from '../../hooks/customHooks/Context';
import { ProjectService } from '../../utils/ApiDataHandler';
import {
    SectorSelectBox,
    SubSectorSelectBox,
    TitleInput,
    TeamInvolvement,
    DecisionDatePicker
} from '../../utils/FramingFormSections';
import { getSubSectorsBySectorId, getSubSectorsFirstElementID } from '../../utils/ProjectDataHandler';
import { CustomCard } from '../../customComponents/CustomCard';
import AddTeamMemberPopup from '../../utils/AddTeamMemberPopup';
import './FramingPage.css';

const FramingPage = (props) => {
    const { setHeading, setCardWidth, setTextAlign, setDescription, setMarginTop } = useContext(cardContext);
    const { selectedProjectData, setSelectedProjectData, updateProjectData } = useContext(projectContext);
    const [sectorData, setSectorData] = useState(null);
    const [subSectorsData, setSubSectorsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // Framing FormData
    const [selectedSectorID, setSelectedSectorID] = useState(selectedProjectData?.sector_id || '');
    const [selectedSubsectorID, setSelectedSubsectorID] = useState(selectedProjectData?.sub_sector_id || '');
    const [title, setTitle] = useState(selectedProjectData?.title || '');
    const [decisionDate, setDecisionDate] = useState(selectedProjectData?.decision_date || '');
    const [members, setMembers] = useState(selectedProjectData?.members || []);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setHeading('How can you decision be framed precisely? ');
        setDescription('');
        setCardWidth("100%");
        setTextAlign("left");
        setMarginTop("2%")
    }, [setHeading, setDescription, setCardWidth, setTextAlign]);

    useEffect(() => {
        const getSectorData = async () => {
            try {
                setIsLoading(true);
                const response = await ProjectService.fetchSectors();
                setSectorData(response);
                let subSectors = getSubSectorsBySectorId(response, selectedSectorID);
                subSectors = [
                    ...subSectors,
                    { id: -2, name: "some of the above" },
                    { id: -1, name: "None of the above" }
                ];
                setSubSectorsData(subSectors);
            } catch (error) {
                console.error('Failed to fetch sectors:', error);
            } finally {
                setIsLoading(false);
            }
        };
        getSectorData();
    }, [selectedSectorID]);

    const validateForm = () => {
        const errors = {};
        if (!selectedSectorID) errors.sector = 'Sector is required';
        if (!selectedSubsectorID) errors.subsector = 'Subsector is required';
        if (!title) errors.title = 'Title is required';
        if (!decisionDate) errors.decisionDate = 'Decision date is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSectorChange = (event) => {
        const newSectorID = event.target.value;
        setSelectedSectorID(newSectorID);
        const subSectors = getSubSectorsBySectorId(sectorData, newSectorID);
        setSubSectorsData(subSectors);
        setSelectedSubsectorID(getSubSectorsFirstElementID(subSectors))
        setSelectedProjectData({
            ...selectedProjectData,
            sector_id: newSectorID,
            sub_sector_id: getSubSectorsFirstElementID(subSectors),
        });
    };

    const handleSubSectorChange = (event) => {

        const newSubsectorID = parseInt(event.target.value);

        if (newSubsectorID === -1) {
            // "None of the above" selected -> send an empty array
            setSelectedSubsectorID(newSubsectorID);
            setSelectedProjectData({
                ...selectedProjectData,
                sub_sector_id: [],
            });
        } else if (newSubsectorID === -2) {
            // "All of the above" selected -> send all sector IDs
            const allSubsectorIDs = subSectorsData
                .filter(subsector => subsector.id > 0)
                .map(subsector => subsector.id);

            setSelectedSubsectorID(newSubsectorID);
            setSelectedProjectData({
                ...selectedProjectData,
                sub_sector_id: allSubsectorIDs,
            });
        } else {
            setSelectedSubsectorID(newSubsectorID);
            setSelectedProjectData({
                ...selectedProjectData,
                sub_sector_id: [newSubsectorID],
            });
        }
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        setTitle(newTitle);
        setSelectedProjectData({
            ...selectedProjectData,
            title: newTitle,
        });
    };

    const handleDateChange = (newDate) => {
        const formattedDate = newDate?.format("YYYY-MM-DD");
        setDecisionDate(formattedDate);
        setSelectedProjectData({
            ...selectedProjectData,
            decision_date: formattedDate,
        });

    };
    const handleNextClick = async () => {
        if (!validateForm()) return;
        try {
            setIsLoading(true);
            setSelectedProjectData({
                ...selectedProjectData,
                members: members || [],
            });
            let projectId;
            if (!selectedProjectData.id) {
                projectId = await ProjectService.createProject(selectedProjectData);
                setSelectedProjectData({ ...selectedProjectData, id: projectId, current_step: 1, is_inprogress: true, });
            } else {
                await ProjectService.updateProject(selectedProjectData, selectedProjectData.id);
                await ProjectService.updateTeamMembers(selectedProjectData.id, members);
            }

            const updatedProjects = await ProjectService.fetchProjects();
            updateProjectData(updatedProjects);
            props.handleNext(projectId);
        } catch (error) {
            console.error('Failed to save project:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedProjectData({
            ...selectedProjectData,
            members: members,
        });
    };

    return (
        <Fragment >
            {isLoading && <CustomLoadingSpinner />}
            {!isLoading && sectorData && subSectorsData && (
                <Fragment >
                    <div className="div-container">
                        <SectorSelectBox
                            sectors={sectorData}
                            value={selectedSectorID}
                            handleChange={handleSectorChange}
                            error={formErrors.sector}
                        />
                        <SubSectorSelectBox
                            subSectors={subSectorsData}
                            value={selectedSubsectorID.length === 0 || selectedSubsectorID === -1
                                ? -1
                                : selectedSubsectorID.length > 1 || selectedSubsectorID === -2
                                    ? -2
                                    : selectedSubsectorID}

                            handleChange={handleSubSectorChange}
                            error={formErrors.subsector}
                        />
                        <TitleInput
                            value={title}
                            handleChange={handleTitleChange}
                            error={formErrors.title}
                        />
                        {  /*  <TeamInvolvement handleOnClickOpen={handleOpenPopup} />*/}
                        <DecisionDatePicker
                            value={decisionDate}
                            handleDateChange={handleDateChange}
                            error={formErrors.decisionDate}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', columnGap: '20px' }}>
                        <Button
                            onClick={handleNextClick}
                            sx={{
                                width: "100px",
                                bgcolor: '#16697A',
                                color: '#ffffff',
                                textTransform: 'none',
                                borderRadius: '5px',
                                '&:hover': { bgcolor: '#16697A', color: '#ffffff' },
                            }}
                        >
                            Next
                        </Button>
                    </div>
                </Fragment>
            )}
            <AddTeamMemberPopup open={isPopupOpen} handleClose={handleClosePopup} members={members} setMembers={setMembers} />
        </Fragment>
    );
};

export default CustomCard(FramingPage);
