import React, { useState } from 'react';
import './PasswordForm.css';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthService } from '../../../utils/ApiDataHandler';

const PasswordForm = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        if (newPassword === confirmPassword) {
            try {
                await AuthService.UpdatePassword(currentPassword, newPassword, confirmPassword);
                setSuccess("Password changed successfully!");
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                setError("Failed to update password. Please try again.");
            }
        } else {
            setError("Passwords do not match!");
        }
    };

    return (
        <div className="password-form">
            <h3>Change Password</h3>
            <p>Please follow the steps below to change your password:</p>
            <ol>
                <li><b>Enter current password: </b>In the first field, enter your current password.</li>
                <li><b>Enter new password:</b> In the second field, enter your new password. Make sure it is strong and secure.</li>
                <li><b>Confirm new password:</b> In the third field, re-enter your new password to confirm.</li>
                <li><b>Submit:</b> Once you have filled out all the fields, click the "Submit" button to change your password.</li>
            </ol>
            <form onSubmit={handleSubmit}>
                <div className="password-group">
                    <TextField
                        label="Current password"
                        type={showCurrentPassword ? "text" : "password"}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className="password-fields-container">


                        <TextField
                            label="New password"
                            type={showNewPassword ? "text" : "password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Confirm new password"
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className="button-group">
                    <Button
                        style={{
                            width: "100px",
                            backgroundColor: '#16697A',
                            color: '#ffffff',
                            textTransform: 'none',
                            borderRadius: '5px',
                        }}
                        type="submit"
                    >
                        Save
                    </Button>
                </div>

            </form>
        </div>
    );
};

export default PasswordForm;